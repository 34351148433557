/*@tailwind preflight;*/

.bg-black {
  background-color: #22292F
}

.bg-white {
  background-color: #FFF
}

.bg-gray-50 {
  background-color: #FAFAFA
}

.bg-gray-100 {
  background-color: #F5F5F5
}

.bg-gray-200 {
  background-color: #EEEEEE
}

.bg-gray-300 {
  background-color: #E0E0E0
}

.bg-red {
  background-color: #F44336
}

.bg-blue-100 {
  background-color: #BBDEFB
}

.bg-blue {
  background-color: #2196F3
}

.bg-blue-gray-300 {
  background-color: #90A4AE
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.bg-center {
  background-position: center
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.bg-contain {
  background-size: contain
}

.border-black {
  border-color: #22292F
}

.border-gray-100 {
  border-color: #F5F5F5
}

.border-gray-500 {
  border-color: #9E9E9E
}

.border-gray {
  border-color: #9E9E9E
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-sm {
  border-radius: .2rem
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-r {
  border-top-right-radius: .4rem;
  border-bottom-right-radius: .4rem
}

.border-1 {
  border-width: 1px
}

.border {
  border-width: 1px
}

.border-t-1 {
  border-top-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.cursor-not-allowed {
  cursor: not-allowed
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.float-right {
  float: right
}

.font-300 {
  font-weight: 300
}

.font-600 {
  font-weight: 600
}

.font-light {
  font-weight: 300
}

.font-bold {
  font-weight: 700
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-32 {
  height: 3.2rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-52 {
  height: 5.2rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-96 {
  height: 9.6rem
}

.h-128 {
  height: 12.8rem
}

.h-160 {
  height: 16rem
}

.h-200 {
  height: 20rem
}

.h-224 {
  height: 22.4rem
}

.h-256 {
  height: 25.6rem
}

.h-512 {
  height: 51.2rem
}

.h-640 {
  height: 64rem
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.leading-none {
  line-height: 1
}

.m-1 {
  margin: 0.25rem
}

.m-2 {
  margin: 0.5rem
}

.m-3 {
  margin: 0.75rem
}

.m-5 {
  margin: 1.25rem
}

.m-8 {
  margin: 0.8rem
}

.m-10 {
  margin: 2.5rem
}

.m-20 {
  margin: 2rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mt-0 {
  margin-top: 0
}

.mb-0 {
  margin-bottom: 0
}

.mt-1 {
  margin-top: 0.25rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.ml-5 {
  margin-left: 1.25rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mr-10 {
  margin-right: 2.5rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.ml-10 {
  margin-left: 2.5rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.ml-32 {
  margin-left: 3.2rem
}

.mt-40 {
  margin-top: 4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mt-52 {
  margin-top: 5.2rem
}

.mt-56 {
  margin-top: 5.6rem
}

.mb-56 {
  margin-bottom: 5.6rem
}

.mt-60 {
  margin-top: 6rem
}

.mt-64 {
  margin-top: 6.4rem
}

.mb-64 {
  margin-bottom: 6.4rem
}

.mt-80 {
  margin-top: 8rem
}

.mb-80 {
  margin-bottom: 8rem
}

.mt-128 {
  margin-top: 12.8rem
}

.mt-200 {
  margin-top: 20rem
}

.mt-224 {
  margin-top: 22.4rem
}

.mb-256 {
  margin-bottom: 25.6rem
}

.mt-auto {
  margin-top: auto
}

.mr-auto {
  margin-right: auto
}

.mb-auto {
  margin-bottom: auto
}

.ml-auto {
  margin-left: auto
}

.-mr-12 {
  margin-right: -1.2rem
}

.max-h-20 {
  max-height: 2rem
}

.max-h-128 {
  max-height: 12.8rem
}

.max-h-256 {
  max-height: 25.6rem
}

.max-h-320 {
  max-height: 32rem
}

.max-h-640 {
  max-height: 64rem
}

.max-h-screen {
  max-height: 100vh
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-md {
  max-width: 64rem
}

.max-w-lg {
  max-width: 80rem
}

.max-w-xl {
  max-width: 96rem
}

.min-h-32 {
  min-height: 3.2rem
}

.min-h-56 {
  min-height: 5.6rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-136 {
  min-height: 13.6rem
}

.min-h-160 {
  min-height: 16rem
}

.min-h-256 {
  min-height: 25.6rem
}

.min-h-512 {
  min-height: 51.2rem
}

.min-h-640 {
  min-height: 64rem
}

.min-h-full {
  min-height: 100%
}

.min-h-screen {
  min-height: 100vh
}

.min-h-xs {
  min-height: 32rem
}

.min-h-sm {
  min-height: 48rem
}

.min-w-0 {
  min-width: 0
}

.min-w-40 {
  min-width: 4rem
}

.min-w-52 {
  min-width: 5.2rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-200 {
  min-width: 20rem
}

.min-w-512 {
  min-width: 51.2rem
}

.object-contain {
  object-fit: contain
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.opacity-100 {
  opacity: 1
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-scroll {
  overflow: scroll
}

.p-0 {
  padding: 0
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-5 {
  padding: 1.25rem
}

.p-8 {
  padding: 0.8rem
}

.p-10 {
  padding: 2.5rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-20 {
  padding: 2rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.p-36 {
  padding: 3.6rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.pt-0 {
  padding-top: 0
}

.pb-0 {
  padding-bottom: 0
}

.pl-0 {
  padding-left: 0
}

.pt-1 {
  padding-top: 0.25rem
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pl-1 {
  padding-left: 0.25rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pt-4 {
  padding-top: 0.4rem
}

.pr-4 {
  padding-right: 0.4rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-5 {
  padding-right: 1.25rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pl-10 {
  padding-left: 2.5rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pb-20 {
  padding-bottom: 2rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pr-24 {
  padding-right: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pb-28 {
  padding-bottom: 2.8rem
}

.pr-32 {
  padding-right: 3.2rem
}

.pb-32 {
  padding-bottom: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pl-40 {
  padding-left: 4rem
}

.pr-48 {
  padding-right: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.shadow-none {
  box-shadow: none
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-white {
  color: #FFF
}

.text-gray-400 {
  color: #BDBDBD
}

.text-gray-500 {
  color: #9E9E9E
}

.text-gray-800 {
  color: #424242
}

.text-gray {
  color: #9E9E9E
}

.text-red-300 {
  color: #E57373
}

.text-red-400 {
  color: #EF5350
}

.text-red-500 {
  color: #F44336
}

.text-red {
  color: #F44336
}

.text-orange-300 {
  color: #FFB74D
}

.text-orange {
  color: #FF9800
}

.text-yellow-500 {
  color: #FFEB3B
}

.text-green-300 {
  color: #81C784
}

.text-green-400 {
  color: #66BB6A
}

.text-green-500 {
  color: #4CAF50
}

.text-green {
  color: #4CAF50
}

.text-blue-300 {
  color: #64B5F6
}

.text-blue-400 {
  color: #42A5F5
}

.text-blue-500 {
  color: #2196F3
}

.text-blue {
  color: #2196F3
}

.text-blue-gray-400 {
  color: #78909C
}

.text-10 {
  font-size: 1rem
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-28 {
  font-size: 2.8rem
}

.text-32 {
  font-size: 3.2rem
}

.text-48 {
  font-size: 4.8rem
}

.text-56 {
  font-size: 5.6rem
}

.text-72 {
  font-size: 7.2rem
}

.text-xs {
  font-size: 1.2rem
}

.text-lg {
  font-size: 1.8rem
}

.text-xl {
  font-size: 2rem
}

.text-5xl {
  font-size: 4.8rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.no-underline {
  text-decoration: none
}

.align-middle {
  vertical-align: middle
}

.align-bottom {
  vertical-align: bottom
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.break-words {
  overflow-wrap: break-word
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-32 {
  width: 3.2rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-52 {
  width: 5.2rem
}

.w-64 {
  width: 6.4rem
}

.w-200 {
  width: 20rem
}

.w-512 {
  width: 51.2rem
}

.w-640 {
  width: 64rem
}

.w-xs {
  width: 32rem
}

.w-xl {
  width: 96rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-1\/6 {
  width: 16.666667%
}

.w-2\/6 {
  width: 33.333333%
}

.w-2\/12 {
  width: 16.666667%
}

.w-9\/12 {
  width: 75%
}

.w-10\/12 {
  width: 83.333333%
}

.w-11\/12 {
  width: 91.666667%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-9999 {
  z-index: 9999
}

@media (min-width: 600px) {

  .sm\:flex {
    display: flex
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:h-256 {
    height: 25.6rem
  }

  .sm\:h-320 {
    height: 32rem
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:p-8 {
    padding: 0.8rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  .sm\:pb-16 {
    padding-bottom: 1.6rem
  }

  .sm\:pl-16 {
    padding-left: 1.6rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: flex
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:w-320 {
    width: 32rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:max-w-4xl {
    max-width: 144rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }
}

@media (min-width: 1920px) {

  .xl\:max-w-5xl {
    max-width: 160rem
  }
}
